import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import clsx from 'clsx';

import { isMobile, isIOS } from '../../../utils';

import IconPlay from '../../Buttons/IconPlay';
import IconStop from '../../Buttons/IconStop';
import IconClose from '../../Buttons/IconClose';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
// import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useAdminDatatrackSend from '../../../hooks/useAdminDatatrackSend/useAdminDatatrackSend';

interface MultiVimeoIds {
  name: string;
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stop: {
      width: '20px',
      height: '20px',
    },
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#FFF',
      color: '#000',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    arrow: {
      color: '#FFF',
    },
    fab: {
      margin: theme.spacing(1),
      backgroundColor: '#ffffff',
      color: '#000000',
      '&.device:hover, &.device:focus': {
        backgroundColor: '#ffffff',
        color: '#000000',
      },
      '&:hover': {
        backgroundColor: '#ff5860',
        [theme.breakpoints.down('xs')]: {
          backgroundColor: '#ffffff',
          color: '#000000',
        },
      },
    },
    fabDisabled: {
      '&.device:hover, &.device:focus': {
        backgroundColor: '#ff5860',
      },
      backgroundColor: '#ff5860',
      '&:hover': {
        [theme.breakpoints.down('xs')]: {
          backgroundColor: '#ff5860',
          color: '#FFFFFF',
        },
      },
    },
    multiVideoContainer: {
      position: 'absolute',
      width: '500px',
      left: '50%',
      top: '0',
      transform: 'translateX(calc(-50% + 32px)) translateY(calc(-100% - 40px))',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#FFF',
      padding: '30px',
      borderRadius: '20px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 17px)',
        position: 'fixed',
        transform: 'translateY(calc(-100% - 40px))',
        left: '0',
        padding: '25px 20px',
      },
    },
    multiVideoItem: {
      cursor: 'pointer',
      border: 'none',
      borderRadius: '30px',
      backgroundColor: '#eee',
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: 500,
      padding: '15px 23px',
      marginBottom: '10px',
      '&:last-child': {
        marginBottom: '0',
      },
      '&:hover': {
        backgroundColor: '#ddd',
      },
      '&:focus': {
        outline: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    multiVideoMediaTitle: {
      marginTop: '0',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '30px',
      right: '30px',
      background: 'none',
      border: 'none',
      padding: '0',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        top: '22px',
        right: '23px',
      },
    },
  })
);

export default function ToggleBgVideoPlayButton(props: { disabled?: boolean }) {
  const [showMultiMenu, setShowMultiMenu] = useState(false);
  const classes = useStyles();
  const { room, isBgVideoPlaying, videoStartedAt } = useVideoContext();

  const [sendMessage, currentLocalDataTrack] = useAdminDatatrackSend();

  const hasMultipleVideos = Boolean(process.env.REACT_APP_MULTI_VIDEOS);
  let MultiVimeoIds: Array<MultiVimeoIds> = [];

  if (hasMultipleVideos) {
    let multiVideosString = process.env.REACT_APP_MULTI_VIDEOS && process.env.REACT_APP_MULTI_VIDEOS.split(',');

    multiVideosString &&
      multiVideosString.forEach(l => {
        const parts = l.split('|');
        MultiVimeoIds = [...MultiVimeoIds, { name: parts[0], id: parts[1] }];
      });
  }

  console.log('MultiVimeoIds', MultiVimeoIds);

  const handleClick = () => {
    if (!isBgVideoPlaying) {
      if (hasMultipleVideos && !showMultiMenu) {
        setShowMultiMenu(true);
        return;
      }

      if (hasMultipleVideos && showMultiMenu) {
        setShowMultiMenu(false);
        return;
      }

      const adminStartTime = Date.now();
      document.dispatchEvent(new CustomEvent('VibeVideoPlay', { detail: { startTime: adminStartTime } }));

      sendMessage(`VibeVideoPlay-${adminStartTime}`);
    } else {
      // trigger the local Admin video stop
      document.dispatchEvent(new CustomEvent('VibeVideoStop'));
      sendMessage('VibeVideoStop');
    }
  };

  const handleVideoClick = (vimeoId: string) => {
    if (!isBgVideoPlaying) {
      const adminStartTime = Date.now();
      const sendString = `VibeVideoPlay-${adminStartTime}-${vimeoId}`;

      document.dispatchEvent(new CustomEvent('VibeVideoPlay', { detail: { startTime: adminStartTime, vimeoId } }));

      sendMessage(sendString);
      setShowMultiMenu(false);
    }
  };

  useEffect(() => {
    const sendCurrentStatus = () => {
      if (isBgVideoPlaying === true && videoStartedAt && currentLocalDataTrack) {
        // Send the message -- giving some time for the new user to connect data track
        sendMessage(`VibeVideoPlay-${videoStartedAt}`, 3000);
      } else if (isBgVideoPlaying === false && currentLocalDataTrack) {
        // Send the message to stop video to the new participants.
        sendMessage('VibeVideoStop', 3000);
      }
    };

    // when participants change - i.e when someone joins the room, admin sends the message of the state of the bgvideo
    room.on('participantConnected', sendCurrentStatus);

    return () => {
      room.off('participantConnected', sendCurrentStatus);
    };
  });

  return (
    <>
      <div style={{ position: 'relative' }}>
        {showMultiMenu && hasMultipleVideos && (
          <div className={classes.multiVideoContainer}>
            <p className={classes.multiVideoMediaTitle}>Media</p>
            <button className={classes.closeButton} onClick={() => setShowMultiMenu(false)}>
              <IconClose></IconClose>
            </button>
            {MultiVimeoIds.map(vid => (
              <button className={classes.multiVideoItem} key={vid.id} onClick={() => handleVideoClick(vid.id)}>
                {vid.name}
              </button>
            ))}
          </div>
        )}
      </div>
      <Tooltip
        arrow
        title={isBgVideoPlaying ? 'STOP VIDEO' : 'PLAY VIDEO'}
        placement="top"
        PopperProps={{ disablePortal: true }}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow, touch: classes.tooltipTouch }}
      >
        <div style={{ position: 'relative' }}>
          <Fab
            className={clsx(classes.fab, {
              [classes.fabDisabled]: isBgVideoPlaying,
              device: isIOS || isMobile,
            })}
            onClick={handleClick}
            disabled={props.disabled}
          >
            {isBgVideoPlaying ? <IconStop className={classes.stop} /> : <IconPlay />}
          </Fab>
        </div>
      </Tooltip>
    </>
  );
}
