import React, { useState, useEffect } from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import './transitions.css';

import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ParticipantInfoAll from '../ParticipantInfoAll/ParticipantInfoAll';
import useParticipantType from '../../hooks/useParticipantType/useParticipantType';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useNoiseGif from '../../hooks/useNoiseGif/useNoiseGif';
import useIsUserActive from '../Controls/useIsUserActive/useIsUserActive';

import MainParticipant from '../MainParticipant/MainParticipant';
import Controls from '../Controls/Controls';
import Preroll from '../Preroll/Preroll';

import { isMobile, isPortrait } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      left: '0',
      textAlign: 'left',
      marginRight: 'auto',
      '& video': {
        marginRight: 'auto',
      },
    },
    right: {
      right: '0',
      textAlign: 'right',
      marginLeft: 'auto',
      '& video': {
        marginLeft: 'auto',
      },
    },
    leftNull: {
      [theme.breakpoints.down('xs')]: {
        height: '33.334%!important',
        top: '16.66667%',
      },
      '.mobile--forced &': {
        height: '33.334%!important',
        top: '16.66667%',
      },
      '&.three-items-mobile': {
        [theme.breakpoints.down('xs')]: {
          height: '33.334%',
          top: '0',
          '.hide-right &': {
            top: '16.66667%',
          },
        },
        '.mobile--forced &': {
          height: '33.334%',
          top: '0',
        },
        '.mobile--forced .hide-right &': {
          height: '33.3334%',
          top: '16.66667%',
        },
      },
    },
    rightNull: {
      [theme.breakpoints.down('xs')]: {
        height: '33.3334%!important',
        top: '50%',
      },
      '.mobile--forced &': {
        height: '33.3334%!important',
        top: '50%',
      },
      '&.three-items-mobile': {
        [theme.breakpoints.down('xs')]: {
          height: '33.3334%',
          top: '66.65%',
          '.hide-left &': {
            top: '50%',
          },
        },
        '.mobile--forced &': {
          height: '33.3334%',
          top: '66.65%',
        },
        '.mobile--forced .hide-left &': {
          height: '33.3334%',
          top: '50%',
        },
      },
    },
    nullHalf: {
      height: '14.0625vw !important',
      width: '50% !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%!important',
        height: '33.3334%!important',
      },
      '.mobile--forced &': {
        width: '100%!important',
        height: '33.3334%!important',
      },
    },
  })
);

const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  overflowY: 'hidden',
  position: 'relative',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `1fr 1fr`,
  gridTemplateRows: `100%`,
  zIndex: 2,
  pointerEvents: 'none',
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'transform 1.2s',
    transformOrigin: 'center',
    '&.shrink-mobile': {
      transition: 'transform 0.6s',
      transform: 'scale(0.65)',
    },
  },
  '&.hidden': {
    display: 'none',
  },
  '.mobile--forced &': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '& video': {
    width: '100%',
    height: '100.3%',
    objectPosition: 'center',
    position: 'relative',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxHeight: 'none',
    },
    '.mobile--forced &': {
      width: '100%',
      height: '100%',
      maxHeight: 'none',
    },
  },
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  width: '100%',
  height: '28.125vw',
  backgroundSize: 'cover',
  transition: 'all 0.2s ease-in-out',
  display: 'grid',
  '&.hide-left, &.hide-right': {
    opacity: '0',
  },
  '&.grid-2': {
    gridTemplateColumns: '1fr 1fr',
  },
  '&.grid-3, &.grid-4': {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    '& video': {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
      left: '50%',
      position: 'relative',
      transform: 'translateX(-50%)',
    },
  },
  '&.grid-5, &.grid-6': {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    '& video': {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
      left: '50%',
      position: 'relative',
      transform: 'translateX(-50%)',
    },
  },
  '&.grid-7, &.grid-8, &.grid-9': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    '& video': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      left: '50%',
      position: 'relative',
      transform: 'translateX(-50%)',
    },
  },
  '& .participant-wrapper:nth-of-type(9) ~ .participant-wrapper': {
    position: 'absolute',
    height: '0',
    width: '0',
  },
  '.wrapper.half &': {
    width: '50%',
    maxHeight: '15vw',
    left: '0!important',
    transform: 'none!important',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxHeight: '33.3334%',
      objectFit: 'cover',
    },
    '.mobile--forced &': {
      width: '100%',
      maxHeight: '33.3334%',
      objectFit: 'cover',
    },
  },
  '&.admin-participants': {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      height: '33.334%!important',
      top: '16.66667%',
      '.hide-left.admin-right &, .hide-right &': {
        top: '33.35%',
      },
    },
    '.mobile--forced &': {
      position: 'absolute',
      height: '33.334%!important',
      top: '16.66667%',
    },
    '.mobile--forced .hide-left.admin-right &, .mobile--forced .hide-right &': {
      position: 'absolute',
      height: '33.3334%!important',
      top: '33.35%',
    },
    '&.three-items-mobile': {
      [theme.breakpoints.down('xs')]: {
        top: '0',
        '.hide-left.admin-right &': {
          top: '50%',
        },
        '.hide-right &': {
          top: '16.66667%',
        },
      },
      '.mobile--forced &': {
        top: '0',
      },
      '.mobile--forced .hide-left.admin-right &': {
        top: '50%',
      },
      '.mobile--forced .hide-right &': {
        top: '16.66667%',
      },
    },
  },
  '&.customer-participants': {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      height: '33.3334%',
      top: '50%',
      '.hide-left &, .hide-right.admin-right &': {
        top: '33.35%',
      },
    },
    '.mobile--forced &': {
      position: 'absolute',
      height: '33.3334%',
      top: '50%',
    },
    '.mobile--forced .hide-left &, .mobile--forced .hide-right.admin-right &': {
      position: 'absolute',
      height: '33.3334%',
      top: '33.35%',
    },
    '&.three-items-mobile': {
      [theme.breakpoints.down('xs')]: {
        top: '66.65%',
        '.hide-left &': {
          top: '50%',
        },
        'hide-right.admin-right &': {
          top: '16.66667%',
        },
      },
      '.mobile--forced &': {
        top: '66.65%',
      },
      '.mobile--forced .hide-left &': {
        top: '50%',
      },
      'mobile--forced .hide-right.admin-right &': {
        top: '16.66667%',
      },
    },
  },
}));

const FullScreenContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100vw',
  zIndex: 1,
  display: 'block',
  top: '0',
  left: '0',
  objectFit: 'cover',
  '& div': {
    height: '100%',
  },
  [theme.breakpoints.down('xs')]: {
    top: '33.3334%',
    height: '33.36%',
    transition: 'transform 1.2s',
    transformOrigin: 'center',
    overflow: 'hidden',
    '&.shrink-mobile': {
      transition: 'transform 0.6s',
      transform: 'scale(0.65)',
    },
    '.hide-left + &': {
      top: '16.66667%',
      transformOrigin: 'bottom',
    },
    '.hide-right + &': {
      top: '50%',
      transformOrigin: 'top',
    },
  },
  '.mobile--forced .hide-left + &': {
    height: '33.36%',
    top: '16.66667%',
  },
  '.mobile--forced .hide-right + &': {
    height: '33.36%',
    top: '50%',
  },
  '.mobile--forced &': {
    top: '33.3334%',
    height: '33.36%',
  },
}));

const NullContainer = styled('div')(({ theme }) => ({
  backgroundSize: 'cover',
  position: 'relative',
  height: '28.125vw',
  width: '100%',
  display: 'block',
  transition: 'all 0.2s ease-in-out',
  [theme.breakpoints.down('xs')]: {
    height: '100%',
    position: 'absolute',
  },
  '.mobile--forced &': {
    height: '100%',
    position: 'absolute',
  },
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
    isBgVideoPlaying,
    hideCameras,
  } = useVideoContext();
  const [timedStartTime, setTimedStartTime] = useState(false);
  const [videoSynced, setVideoSynced] = useState(false);

  const noiseGif = useNoiseGif();
  const participants = useParticipants();
  const adminParticipant = useParticipantType('admin');
  const modelParticipant = useParticipantType('model');
  const customerParticipant = useParticipantType('customer');
  const screenShareParticipant = useScreenShareParticipant();
  const isUserActive = useIsUserActive();
  const isMobilePortrait = isMobile && isPortrait();

  const isLocalParticipantAdmin = JSON.parse(localParticipant.identity).role === 'admin';

  const fetchTimeUrl = process.env.REACT_APP_FETCH_TIME_URL;

  const preRoll = process.env.REACT_APP_PREROLL || process.env.REACT_APP_PREROLL_IMAGE;
  const modelPreRoll =
    process.env.REACT_APP_MODEL_PREROLL && process.env.REACT_APP_MODEL_PREROLL.toLowerCase() === 'true';

  const singleVimeoId = process.env.REACT_APP_VIMEO_ID;
  const timedStream = Boolean(adminParticipant && JSON.parse(adminParticipant.identity).stream && singleVimeoId);

  const classes = useStyles();

  const shouldRenderStream = timedStream || isBgVideoPlaying;

  const isRenderingStream = isBgVideoPlaying;
  const isStreamInView = isRenderingStream && videoSynced;

  const threeItemsMobile = modelParticipant || isStreamInView || screenShareParticipant;

  if (timedStream && !timedStartTime && isLocalParticipantAdmin) {
    if (fetchTimeUrl) {
      fetch(`${fetchTimeUrl}`)
        .then(response => response.json())
        .then(data => {
          const fetchStartTime = Date.parse(data.startTime);
          let intervalTime = 100;
          const interval = setInterval(() => {
            intervalTime = 3000;
            if (fetchStartTime < Date.now()) {
              setTimedStartTime(true);
              document.dispatchEvent(new CustomEvent('VibeVideoPlay', { detail: { startTime: fetchStartTime } }));
              clearInterval(interval);
            }
          }, intervalTime);
        });
    } else {
      const startTime10Secs = Date.now() + 10000;
      setTimedStartTime(true);
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('VibeVideoPlay', { detail: { startTime: startTime10Secs } }));
      }, 10000);
    }
  }

  const adminRemoteParticipants = participants.filter(participant => JSON.parse(participant.identity).role === 'admin');

  const customerRemoteParticipants = participants.filter(
    participant => JSON.parse(participant.identity).role === 'customer'
  );

  let numberOfAdmins = adminRemoteParticipants.length;
  numberOfAdmins = JSON.parse(localParticipant.identity).role === 'admin' ? numberOfAdmins + 1 : numberOfAdmins;
  numberOfAdmins = numberOfAdmins > 9 ? 9 : numberOfAdmins;

  let numberOfCustomers = customerRemoteParticipants.length;
  numberOfCustomers =
    JSON.parse(localParticipant.identity).role === 'customer' ? numberOfCustomers + 1 : numberOfCustomers;
  numberOfCustomers = numberOfCustomers > 9 ? 9 : numberOfCustomers;

  const isAdminRight = window.location.search.toLowerCase().includes('adminright=true');

  const noAdmin = !adminParticipant;
  const noModel = !modelParticipant;

  const leftParticipant = isAdminRight ? customerParticipant : adminParticipant;
  const leftRemoteParticipants = isAdminRight ? customerRemoteParticipants : adminRemoteParticipants;
  const rightParticipant = isAdminRight ? adminParticipant : customerParticipant;
  const rightRemoteParticipants = isAdminRight ? adminRemoteParticipants : customerRemoteParticipants;

  const isHideRight = Boolean(
    window.location.search.toLowerCase().includes('hideright=true') ||
      (process.env.REACT_APP_HIDE_RIGHT && process.env.REACT_APP_HIDE_RIGHT.toLowerCase() === 'true')
  );

  const isHideLeft = Boolean(
    window.location.search.toLowerCase().includes('hideleft=true') ||
      (process.env.REACT_APP_HIDE_LEFT && process.env.REACT_APP_HIDE_LEFT.toLowerCase() === 'true')
  );

  const showPreroll = modelPreRoll ? preRoll && noModel && noAdmin : preRoll && noAdmin;

  useEffect(() => {
    // when the admin in the room leaves, reset hideall
    if (noAdmin) {
      document.dispatchEvent(new CustomEvent('VibeShowAll'));
      if (isBgVideoPlaying) document.dispatchEvent(new CustomEvent('VibeVideoStop'));
    }
  }, [noAdmin, isBgVideoPlaying]);

  if (showPreroll) {
    return <Preroll />;
  }

  return (
    <>
      <Container
        className={clsx('wrapper', {
          half: modelParticipant || screenShareParticipant || isStreamInView,
          'admin-right': isAdminRight,
          'admin-left': !isAdminRight,
          'hide-left': isHideLeft,
          'hide-right': isHideRight,
          hidden: hideCameras,
          'shrink-mobile': isUserActive && isMobilePortrait && threeItemsMobile,
        })}
      >
        {leftParticipant ? (
          <ScrollContainer
            style={{
              backgroundImage: `url(${noiseGif})`,
            }}
            className={clsx(
              classes.left,
              {
                'three-items-mobile': threeItemsMobile,
                'admin-participants': !isAdminRight,
                'customer-participants': isAdminRight,
                'hide-left': isHideLeft,
              },
              `grid-${isAdminRight ? numberOfCustomers : numberOfAdmins}`
            )}
          >
            {!isMobile && !(isStreamInView || screenShareParticipant) && (
              <ParticipantInfoAll localParticipant={localParticipant} user={isAdminRight ? 'customer' : 'admin'} />
            )}
            {((!isAdminRight && JSON.parse(localParticipant.identity).role === 'admin') ||
              (isAdminRight && JSON.parse(localParticipant.identity).role === 'customer')) && (
              <Participant
                key={localParticipant.sid}
                participant={localParticipant}
                isLocalParticipant={true}
                isSelected={false}
                onClick={() => {}}
                enableScreenShare={false}
                audioOnly={isHideLeft}
              />
            )}

            {leftRemoteParticipants.map(participant => (
              <Participant key={participant.sid} participant={participant} isSelected={false} onClick={() => {}} />
            ))}
          </ScrollContainer>
        ) : (
          <NullContainer
            style={{
              backgroundImage: `url(${noiseGif})`,
              opacity: isHideLeft ? '0' : '1',
            }}
            className={clsx(
              {
                [classes.nullHalf]: modelParticipant || screenShareParticipant || isStreamInView,
                'three-items-mobile': threeItemsMobile,
              },
              classes.leftNull
            )}
          />
        )}
        {rightParticipant ? (
          <ScrollContainer
            style={{
              backgroundImage: `url(${noiseGif})`,
            }}
            className={clsx(
              classes.right,
              {
                'three-items-mobile': threeItemsMobile,
                'customer-participants': !isAdminRight,
                'admin-participants': isAdminRight,
                'hide-right': isHideRight,
              },
              `grid-${isAdminRight ? numberOfAdmins : numberOfCustomers}`
            )}
          >
            {!isMobile && !(isStreamInView || screenShareParticipant) && (
              <ParticipantInfoAll localParticipant={localParticipant} user={isAdminRight ? 'admin' : 'customer'} />
            )}
            {((isAdminRight && JSON.parse(localParticipant.identity).role === 'admin') ||
              (!isAdminRight && JSON.parse(localParticipant.identity).role === 'customer')) && (
              <Participant
                key={localParticipant.sid}
                participant={localParticipant}
                isLocalParticipant={true}
                isSelected={false}
                onClick={() => {}}
                enableScreenShare={false}
                audioOnly={isHideRight}
              />
            )}

            {rightRemoteParticipants.map(p => (
              <Participant key={p.sid} participant={p} isSelected={false} onClick={() => {}} />
            ))}
          </ScrollContainer>
        ) : (
          <NullContainer
            style={{
              backgroundImage: `url(${noiseGif})`,
              opacity: isHideRight ? '0' : '1',
            }}
            className={clsx(
              {
                [classes.nullHalf]: modelParticipant || screenShareParticipant || isStreamInView,
                [classes.right]: modelParticipant || screenShareParticipant || isStreamInView,
                'three-items-mobile': threeItemsMobile,
              },
              classes.rightNull
            )}
          />
        )}
      </Container>

      <FullScreenContainer
        className={clsx('fullscreen', {
          revealed: modelParticipant || screenShareParticipant || isStreamInView,
          'shrink-mobile': isUserActive && isMobilePortrait && threeItemsMobile,
        })}
        id="mainParticipantContainer"
      >
        {!shouldRenderStream || isRenderingStream ? (
          <MainParticipant setVideoSynced={() => setVideoSynced(true)} />
        ) : (
          <></>
        )}
      </FullScreenContainer>

      <Controls />
    </>
  );
}
