import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { isMobile, isIOS } from '../../utils';

import TapForSound from '../Buttons/TapForSound';

interface ParamTypes {
  URLLocation: string;
}

interface LocationPreroll {
  location: string;
  url: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
      width: '100%',
      zIndex: 9999,
    },
    banner: {
      margin: '0',
      backgroundColor: '#FFFFFF',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '21.5px',
      padding: '20px 0',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
      },
    },
    text: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      animation: `$sliding 10s linear infinite`,
    },
    assetContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    video: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 1,
    },
    image: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 0,
      // objectPosition: 'top',
    },
    desktopVideo: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mobileVideo: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    '@keyframes sliding': {
      '0%': {
        transform: 'translateX(0)',
      },
      '100%': {
        transform: 'translateX(-10%)',
      },
    },
  })
);

export default function Preroll() {
  const { URLLocation } = useParams<ParamTypes>();

  let locationPrerolls: Array<LocationPreroll> = [];
  let locationPrerollsMob: Array<LocationPreroll> = [];

  let locationPrerollsImg: Array<LocationPreroll> = [];
  let locationPrerollsImgMob: Array<LocationPreroll> = [];

  const preRollBannerText = process.env.REACT_APP_PREROLL_BANNER_TEXT;

  if (process.env.REACT_APP_LOCATION_PREROLL_VIDEO) {
    let locationPrerollString = process.env.REACT_APP_LOCATION_PREROLL_VIDEO.split(',');

    locationPrerollString.forEach(l => {
      const parts = l.split('|');
      locationPrerolls = [...locationPrerolls, { location: parts[0], url: parts[1] }];
    });
  }

  if (process.env.REACT_APP_LOCATION_PREROLL_VIDEO_MOBILE) {
    let locationPrerollMobString = process.env.REACT_APP_LOCATION_PREROLL_VIDEO_MOBILE.split(',');

    locationPrerollMobString.forEach(l => {
      const parts = l.split('|');
      locationPrerollsMob = [...locationPrerollsMob, { location: parts[0], url: parts[1] }];
    });
  }

  if (process.env.REACT_APP_LOCATION_PREROLL_IMAGE) {
    let locationPrerollImgString = process.env.REACT_APP_LOCATION_PREROLL_IMAGE.split(',');

    locationPrerollImgString.forEach(l => {
      const parts = l.split('|');
      locationPrerollsImg = [...locationPrerollsImg, { location: parts[0], url: parts[1] }];
    });
  }

  if (process.env.REACT_APP_LOCATION_PREROLL_IMAGE_MOBILE) {
    let locationPrerollImgStringMob = process.env.REACT_APP_LOCATION_PREROLL_IMAGE_MOBILE.split(',');

    locationPrerollImgStringMob.forEach(l => {
      const parts = l.split('|');
      locationPrerollsImgMob = [...locationPrerollsImgMob, { location: parts[0], url: parts[1] }];
    });
  }

  const locationPrerollVideo = URLLocation && locationPrerolls.find(l => l.location === URLLocation.toLowerCase());
  const locationPrerollMobVideo =
    URLLocation && locationPrerollsMob.find(l => l.location === URLLocation.toLowerCase());

  const locationPrerollImg = URLLocation && locationPrerollsImg.find(l => l.location === URLLocation.toLowerCase());
  const locationPrerollMobImg =
    URLLocation && locationPrerollsImgMob.find(l => l.location === URLLocation.toLowerCase());

  const preRollVideo = locationPrerollVideo ? locationPrerollVideo!.url : process.env.REACT_APP_PREROLL;
  const preRollVideoMobile = locationPrerollMobVideo
    ? locationPrerollMobVideo!.url
    : process.env.REACT_APP_PREROLL_MOBILE;

  const preRollImage = process.env.REACT_APP_PREROLL_IMAGE;
  const preRollImageMobile = process.env.REACT_APP_PREROLL_IMAGE_MOBILE;

  // console.log('rendering');
  const classes = useStyles();
  const [isMuted, setIsMuted] = useState(Boolean(isMobile || isIOS));

  useEffect(() => {
    const unmute = () => {
      setIsMuted(false);
      document.querySelectorAll<HTMLMediaElement>('.pre-roll-video').forEach(v => {
        v.pause();
        v.muted = false;
        setTimeout(() => {
          v.play();
        }, 200);
      });
    };

    if (isMuted) {
      document.addEventListener('click', unmute, { once: true });
    }

    return () => {
      document.removeEventListener('click', unmute);
    };
  });

  // const bannerText = preRollBannerText || 'PLEASE WAIT FOR THE HOST TO BEGIN PRADA VIRTUAL STYLING SESSION - ';
  const bannerText = preRollBannerText || ""

  return (
    <div className={classes.container}>
      {bannerText.length > 0 &&
        <p className={classes.banner}>
          <span className={classes.text}>{`${bannerText} `.repeat(10)}</span>
        </p>
      }
      <div className={classes.assetContainer}>
        {((preRollVideoMobile && !isMobile) || !preRollVideoMobile) && (
          <video
            className={`pre-roll-video ${classes.video} ${preRollVideoMobile ? classes.desktopVideo : ''}`}
            loop
            playsInline
            autoPlay
            src={preRollVideo}
            muted={Boolean(isIOS)}
          ></video>
        )}
        {preRollVideoMobile && isMobile && (
          <video
            className={`${classes.video} ${classes.mobileVideo} pre-roll-video`}
            muted={true}
            loop
            playsInline
            autoPlay
            src={preRollVideoMobile}
          ></video>
        )}
        {isMuted && preRollVideo && <TapForSound></TapForSound>}
        {locationPrerollImg && (
          <>
            <img
              className={classes.image}
              src={isMobile && locationPrerollMobImg ? locationPrerollMobImg!.url : locationPrerollImg!.url}
              alt=""
            />
          </>
        )}
        {preRollImage && (
          <>
            <img
              className={classes.image}
              src={isMobile && preRollImageMobile ? preRollImageMobile : preRollImage}
              alt=""
            />
          </>
        )}
      </div>
    </div>
  );
}
