import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Participant } from 'twilio-video';
import useParticipants from '../../hooks/useParticipants/useParticipants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      overflow: 'hidden',
      '& video': {
        filter: 'none',
      },
      '& svg': {
        stroke: 'black',
        strokeWidth: '0.8px',
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '0.4em',
      width: '100%',
      background: 'transparent',
      top: '0',
      left: '0',
    },
    hideVideo: {
      background: 'black',
    },
    name: {
      fontSize: 36,
      fontWeight: 300,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '48px',
      textTransform: 'uppercase',
      '.admin-right .admin &, .admin-left .customer &': {
        right: 0,
        left: 'auto',
        textAlign: 'right',
      },
      '.admin-left .admin &, .admin-right .customer &': {
        right: 'auto',
        left: '0',
        textAlign: 'left',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        bottom: '0px',
        padding: '30px 20px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
        bottom: '6px',
        padding: '30px 20px',
      },
    },
    city: {
      fontSize: 18,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '0',
      right: 'auto',
      textTransform: 'uppercase',
      '.admin-right .admin &, .admin-left .customer &': {
        right: 0,
        left: 'auto',
        textAlign: 'right',
      },
      '.admin-left .admin &, .admin-right .customer &': {
        right: 'auto',
        left: '0',
        textAlign: 'left',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '0',
        fontSize: 12,
        padding: '30px 20px 15px',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0',
        fontSize: 12,
        padding: '30px 20px 15px',
      },
    },
    // left: {
    //   left: '0',
    //   textAlign: 'left',
    //   marginRight: 'auto',
    // },
    // right: {
    //   right: '0',
    //   textAlign: 'right',
    //   marginLeft: 'auto',
    // },
    infoRow: {
      display: 'block',
      position: 'absolute',
      height: '100%',
      width: '50vw',
      top: '0',
      zIndex: 1,
      '&.customer': {
        '.admin-right &': {
          left: '0',
          textAlignt: 'left',
          right: 'auto',
        },
        right: '0',
        textAlign: 'right',
        left: 'auto',
      },
      '&.admin': {
        '.admin-right &': {
          right: '0',
          textAlign: 'right',
          left: 'auto',
        },
        left: '0',
        textAlignt: 'left',
        right: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '.mobile--forced &': {
        display: 'none',
      },
    },
  })
);

interface ParticipantInfoAllProps {
  localParticipant: Participant;
  user: string;
}

// user is the role of the people this component is outputting
// d is localparticipant identity

// if user === 'customer' and customers participants > 4
//    if local participant is admin: show total number of customers and total number of ghosts
// if user === 'admin'
// show normal -- what's already showing

export default function ParticipantInfoAll({ localParticipant, user }: ParticipantInfoAllProps) {
  const participants = useParticipants();
  const classes = useStyles();
  const d = JSON.parse(localParticipant.identity);
  let names = [];
  let cities = [];

  const ghostParticipants = participants.filter(p => JSON.parse(p.identity).role === 'ghost');

  const customerParticipants =
    d.role === 'customer'
      ? participants.filter(p => JSON.parse(p.identity).role === 'customer').length + 1
      : participants.filter(p => JSON.parse(p.identity).role === 'customer').length;

  // console.log('participant info local user', d.role);
  const hideAllNames = process.env.REACT_APP_HIDE_ALL_NAMES && process.env.REACT_APP_HIDE_ALL_NAMES === 'true';

  const hideOtherNames =
    process.env.REACT_APP_HIDE_OTHER_NAMES && process.env.REACT_APP_HIDE_OTHER_NAMES.toLowerCase() === 'true';
  const eventName = process.env.REACT_APP_EVENT_NAME;

  if (hideAllNames) {
    return (
      <div className={classes.infoRow + ' ' + user}>
        <h4 className={classes.name}> </h4>
      </div>
    );
    
  }

  if (hideOtherNames && user === 'customer') {
    if (d.role === 'customer') {
      return (
        <div className={classes.infoRow + ' ' + user}>
          <h4 className={classes.name}>{d.name}</h4>
          {eventName && <h4 className={classes.city}>{eventName}</h4>}
        </div>
      );
    }

    // if (d.role === 'ghost') {
    //   return (
    //     <div className={classes.infoRow + ' ' + user}>{eventName && <h4 className={classes.city}>{eventName}</h4>}</div>
    //   );
    // }

    // all other user types: model, admin, ghost
    if (customerParticipants > 2) {
      return (
        <div className={classes.infoRow + ' ' + user}>
          <h4 className={classes.name}>{customerParticipants} Guests</h4>
          <h4 className={classes.city}>
            {d.role === 'ghost' ? ghostParticipants.length + 1 : ghostParticipants.length} Viewers
          </h4>
        </div>
      );
    }
  }

  if (user === 'customer' && customerParticipants > 4) {
    // model, ghost, admin
    if (d.role !== 'customer') {
      return (
        <div className={classes.infoRow + ' ' + user}>
          <h4 className={classes.name}>{customerParticipants} Guests</h4>
          <h4 className={classes.city}>
            {d.role !== 'ghost' ? ghostParticipants.length : ghostParticipants.length + 1} Viewers
          </h4>
        </div>
      );
    } else {
      // customer
      return (
        <div className={classes.infoRow + ' ' + user}>
          <h4 className={classes.name}>d.name</h4>
          <h4 className={classes.city}>{d.city}</h4>
        </div>
      );
    }
  }

  for (let i = 0; i < participants.length; i++) {
    const p = participants[i];
    const json = JSON.parse(p.identity);
    if (json.role === user) {
      if (json.name) names.push(json.name);
      if (json.city) cities.push(json.city);
    }
  }
  if (d.role === user) {
    if (d.name) names.push(d.name);
    if (d.city) cities.push(d.city);
  }

  return (
    <div className={classes.infoRow + ' ' + user}>
      <h4 className={classes.name}>{names.join(' & ')}</h4>
      <h4 className={classes.city}>{eventName && user === 'customer' ? eventName : cities.join(' & ')}</h4>
    </div>
  );
}
